import { CssBaseline } from '@material-ui/core';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { lazy, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import { routeRedirects } from '@agency/routes/route-redirects';
import ROUTES from '@agency/routes/routes.constant';
import { getBaseUrl } from '@core/base-url';
import { ConfigContext, useAgencyConstants, useAppUrl } from '@core/contexts/ConfigContext';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Viewings } from '@modules/viewings/Viewings';
import { browserEventActions } from '@redux/reducers/browserEvent/browserEventReducer';
import { Confirmation } from '@shared/components/confirmation';
import { ErrorHandler } from '@shared/components/error-handler';
import { Layout } from '@shared/components/layout';
import { LayoutFullScreen } from '@shared/components/layout-full-screen';
import LazyLoad from '@shared/components/lazy-load';
import { Notification } from '@shared/components/notification';
import { sendGaEvent, useGoogleAnalytics } from '@shared/hooks/googleAnalytics';
import { useHotjar } from '@shared/hooks/useHotJar';
import { getDefaultPath } from '@shared/utils/route';

import { styles } from './App.styles';

const Advisory = lazy(() => import('@agency/modules/advisory'));
const Campaigns = lazy(() => import('@agency/modules/campaigns'));
const CRM = lazy(() => import('@agency/modules/crm'));
const Dashboard = lazy(() => import('@agency/modules/dashboard'));
const DataManagement = lazy(() => import('@agency/modules/data-management'));
const Diary = lazy(() => import('@agency/modules/diary'));
const DisposalReportingPresent = lazy(() => import('@shared/components/disposal-reporting-present'));
const Disposals = lazy(() => import('@agency/modules/disposals'));
const Insights = lazy(() => import('@agency/modules/insights'));
const Leases = lazy(() => import('@agency/modules/leases'));
const MarketplaceDashboard = lazy(() => import('@agency/modules/marketplace-dashboard'));
const MarketplaceMembers = lazy(() => import('@agency/modules/marketplace-members'));
const MarketplaceEvents = lazy(() => import('@agency/modules/marketplace-events'));
const MarketplacePreferences = lazy(() => import('@agency/modules/marketplace-preferences'));
const Properties = lazy(() => import('@agency/modules/properties'));
const Requirements = lazy(() => import('@agency/modules/requirements'));
const Sales = lazy(() => import('@agency/modules/sales'));
const Settings = lazy(() => import('@agency/modules/settings'));
const SocietyDisposals = lazy(() => import('@agency/modules/society-disposals'));
const SocietyInsights = lazy(() => import('@agency/modules/society-insights'));
const StyleGuide = lazy(() => import('@agency/modules/style-guide'));
const Team = lazy(() => import('@agency/modules/team'));
const UpgradeToPro = lazy(() => import('@agency/modules/upgrade-to-pro'));
const WipFees = lazy(() => import('@agency/modules/wip-fees'));

export type AppProps = WithStyles<typeof styles>;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Filler
);

const AppComponent: React.FC<AppProps> = ({ classes }) => {
  const { appUrl } = useAppUrl();
  const { constants } = useAgencyConstants();
  const { config, error } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const isPresentation = location.pathname.startsWith('/present');

  useGoogleAnalytics(() => [
    {
      gtagOptions: { send_page_view: false, user_id: config.user.id },
      trackingId: config.googleAnalyticsTrackingId,
    },
  ]);

  useHotjar({ hotjarId: constants.HOTJAR_ID });

  // Listen for changes on history
  useEffect(() => {
    let sendGaTimeout: number | undefined;

    const unregister = history.listen((location) => {
      if (history.action === 'POP') {
        dispatch(browserEventActions.backForwardBrowserEvent(location.search));
      } else {
        dispatch(browserEventActions.urlBrowserEvent());
      }

      // Submit page views
      clearTimeout(sendGaTimeout);
      sendGaTimeout = setTimeout(() => sendGaEvent(location.pathname, location.search), 100);
    });

    return () => {
      clearTimeout(sendGaTimeout);
      unregister();
    };
  }, [history, dispatch]);

  const routes = useMemo(() => {
    const module: Array<{
      access: boolean;
      routeProps: MergeTypes<{ component: React.ComponentType<any> }, Omit<RouteProps, 'component'>>;
    }> = [
      // --- Organisation ---
      {
        access: config.appNav.organisation_home.visible,
        routeProps: { path: ROUTES.organisation.dashboard, component: Dashboard },
      },
      {
        access: config.appNav.organisation_properties.visible,
        routeProps: { path: ROUTES.organisation.properties.root, component: Properties },
      },
      {
        access: config.appNav.organisation_disposals.visible,
        routeProps: { path: ROUTES.organisation.disposals.root, component: Disposals },
      },
      {
        access: config.appNav.organisation_requirements.visible,
        routeProps: { path: ROUTES.organisation.requirements.root, component: Requirements },
      },
      {
        access: config.appNav.organisation_advisory.visible,
        routeProps: {
          path: ROUTES.organisation.advisory.root,
          component: Advisory,
        },
      },
      {
        access: config.appNav.organisation_leases.visible,
        routeProps: { path: ROUTES.organisation.leases.root, component: Leases },
      },
      {
        access: config.appNav.organisation_sales.visible,
        routeProps: { path: ROUTES.organisation.sales.root, component: Sales },
      },
      {
        access: config.appNav.organisation_crm.visible,
        routeProps: { path: ROUTES.organisation.crm.root, component: CRM },
      },
      {
        access: config.appNav.organisation_wip_fees.visible,
        routeProps: {
          path: ROUTES.organisation.wipFees.root,
          component: WipFees,
        },
      },
      // PLACEHOLDER: organisation_tasks
      {
        // NOTE: Not released yet
        access: false,
        routeProps: { path: ROUTES.organisation.diary, component: Diary },
      },
      {
        // NOTE: Not released yet
        access: false,
        // access: config.appNav.organisation_viewings.visible,
        routeProps: { path: ROUTES.organisation.viewings, component: Viewings },
      },
      {
        access: config.appNav.organisation_insights.visible,
        routeProps: {
          path: ROUTES.organisation.insights.root,
          component: Insights,
        },
      },
      {
        // Refer to permission instead, since we can either show AngularJS or React
        access: !!config.featuresEnabled?.webapp.email_campaigns_template_builder,
        routeProps: { path: ROUTES.organisation.campaigns.root, component: Campaigns },
      },
      // --- Marketplace ---
      {
        access: config.appNav.marketplace_dashboard.visible,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.dashboard}`,
          component: MarketplaceDashboard,
        },
      },
      {
        // NOTE: Not released yet
        access: false,
        // access: config.appNav.marketplace_disposals.visible,
        routeProps: { path: ROUTES.marketplace.disposals, component: SocietyDisposals },
      },
      // PLACEHOLDER: marketplace_requirements
      {
        access: config.appNav.marketplace_members.visible,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.members}`,
          component: MarketplaceMembers,
        },
      },
      {
        // NOTE: Not released yet
        access: false,
        // access: config.appNav.marketplace_events.visible,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.events}`,
          component: (props: any) => <MarketplaceEvents {...props} />,
        },
      },
      {
        access: config.appNav.marketplace_insights.visible,
        routeProps: { path: ROUTES.marketplace.insights.root, component: SocietyInsights },
      },
      {
        // Check either top-level visible, or settings visible
        access: config.appNav.marketplace_preferences.visible || config.appNav.settings_marketplace_preferences.visible,
        routeProps: {
          path: `${ROUTES.marketplace.root}${ROUTES.marketplace.preferences}`,
          component: MarketplacePreferences,
        },
      },
      // --- Settings ---
      {
        access: config.appNav.settings_data_management.visible,
        routeProps: { path: ROUTES.organisation.dataManagement.root, component: DataManagement },
      },
      // NOTE: Refer to Marketplace above for settings_marketplace_preferences
      {
        access: config.appNav.settings_teams.visible,
        routeProps: { path: `${ROUTES.organisation.team.root}${ROUTES.organisation.team.members}`, component: Team },
      },
      {
        access: config.appNav.settings_style_guide.visible,
        routeProps: { path: ROUTES.styleGuide.root, component: StyleGuide },
      },
      {
        access: config.appNav.settings_profile.visible,
        routeProps: { path: ROUTES.organisation.settings.root, component: Settings },
      },
      {
        access: true,
        routeProps: {
          path: ROUTES.logOut,
          component: () => {
            window.location.href = `${getBaseUrl()}logout`;
            return null;
          },
        },
      },
      // Presentation routes
      {
        access: true,
        routeProps: {
          path: ROUTES.present.disposalReport,
          component: DisposalReportingPresent,
        },
      },
      // --- Other ---
      {
        access: true,
        routeProps: { path: ROUTES.upgradeToPro.root, component: UpgradeToPro },
      },
      {
        access: true,
        routeProps: {
          path: ROUTES.defaultRedirect,
          component: () => {
            const defaultUrl = getDefaultPath(appUrl, config.appNav);
            if (defaultUrl.startsWith(config.appUrlLegacy)) {
              window.location.href = defaultUrl;
            } else {
              history.push(defaultUrl);
            }
            return null;
          },
        },
      },
    ];

    return module.filter(({ access }) => access).map(({ access, ...otherProps }) => otherProps);
  }, [config.appNav]);

  if (error) {
    return (
      <ErrorHandler
        heading={error || `Sorry, we're having a few technical troubles right now.`}
        classes={{ root: classes.errorHandler }}
      />
    );
  }

  return (
    <IntercomProvider
      appId={config.intercomId}
      autoBoot={config.intercomEnabled}
      autoBootProps={{
        // Keep consistent with agency AngularJS app + landlord React app
        email: config.user.email,
        name: config.user.name,
        userHash: config.intercom_hash,
        userId: config.user.id.toString(),
      }}
    >
      <CssBaseline />
      <Confirmation />
      <Notification />
      {isPresentation ? (
        <LayoutFullScreen googleMapsApiKey={constants.GOOGLE_MAPS_HTTP_API}>
          <LazyLoad withFallback={false}>
            <Switch>
              {routes.map(({ routeProps: { component: $component, ...otherRouteProps } }) => (
                <Route key={String(otherRouteProps.path)} component={$component} {...otherRouteProps} />
              ))}
            </Switch>
          </LazyLoad>
        </LayoutFullScreen>
      ) : (
        <Layout googleMapsApiKey={constants.GOOGLE_MAPS_HTTP_API}>
          <LazyLoad withFallback={false}>
            <Switch>
              {routeRedirects.map(({ from, to }) => (
                <Redirect key={from} from={from} to={to} />
              ))}

              {/* Routes */}
              {routes.map(({ routeProps: { component: $component, ...otherRouteProps } }) => (
                <Route key={String(otherRouteProps.path)} component={$component} {...otherRouteProps} />
              ))}
              <Redirect exact from={ROUTES.initial} to={getDefaultPath(appUrl, config.appNav)} />
              <Redirect to={ROUTES.defaultRedirect} />
            </Switch>
          </LazyLoad>
        </Layout>
      )}
    </IntercomProvider>
  );
};

export const App = withStyles(styles)(AppComponent);
