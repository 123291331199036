const baseUrls = {
  dev: {
    appAgency: 'https://app.agents-society-dev.com',
    appLandlord: 'https://landlord.agents-society-dev.com',
    proxy: 'https://agents-society-dev.com/',
  },
  preprod: {
    appAgency: 'https://app.agents-society-preprod.com',
    appLandlord: 'https://landlord.agents-society-preprod.com',
    proxy: 'https://agents-society-preprod.com/',
  },
  test: {
    appAgency: 'https://app.agents-society-test.com',
    appLandlord: 'https://landlord.agents-society-test.com',
    proxy: 'https://agents-society-test.com/',
  },
  demo: {
    appAgency: 'https://app.agents-society-demo.com',
    appLandlord: 'https://landlord.agents-society-demo.com',
    proxy: 'https://agents-society-demo.com/',
  },
  presentation: {
    appAgency: 'https://app.agents-society-presentation.com',
    appLandlord: 'https://landlord.agents-society-presentation.com',
    proxy: 'https://agents-society-presentation.com/',
  },
  stage: {
    appAgency: 'https://app.agents-society-stage.com',
    appLandlord: 'https://landlord.agents-society-stage.com',
    proxy: 'https://agents-society-stage.com/',
  },
  gk: {
    appAgency: 'https://app.agents-society-dev-gk.com',
    appLandlord: 'https://landlord.agents-society-dev-gk.com',
    proxy: 'https://agents-society-dev-gk.com/',
  },
  usyk: {
    appAgency: 'https://app.agents-society-dev-usyk.com',
    appLandlord: 'https://landlord.agents-society-dev-usyk.com',
    proxy: 'https://agents-society-dev-usyk.com/',
  },
  vidic: {
    appAgency: 'https://app.agents-society-dev-vidic.com',
    appLandlord: 'https://landlord.agents-society-dev-vidic.com',
    proxy: 'https://agents-society-dev-vidic.com/',
  },
  fury: {
    appAgency: 'https://app.agents-society-dev-fury.com',
    appLandlord: 'https://landlord.agents-society-dev-fury.com',
    proxy: 'https://agents-society-dev-fury.com/',
  },
  dubois: {
    appAgency: 'https://app.agents-society-dev-dubois.com',
    appLandlord: 'https://landlord.agents-society-dev-dubois.com',
    proxy: 'https://agents-society-dev-dubois.com/',
  },
  rocks: {
    appAgency: 'https://app.kato.rocks',
    appLandlord: 'https://landlord.kato.rocks',
    proxy: 'https://kato.rocks/',
  },
  khan: {
    appAgency: 'https://app.agents-society-dev-khan.com',
    appLandlord: 'https://landlord.agents-society-dev-khan.com',
    proxy: 'https://agents-society-dev-khan.com/',
  },
  thumper: {
    appAgency: 'https://app.agents-society-dev-thumper.com',
    appLandlord: 'https://landlord.agents-society-dev-thumper.com',
    proxy: 'https://agents-society-dev-thumper.com/',
  },
  creed: {
    appAgency: 'https://app.agents-society-dev-creed.com',
    appLandlord: 'https://landlord.agents-society-dev-creed.com',
    proxy: 'https://agents-society-dev-creed.com/',
  },
  joshua: {
    appAgency: 'https://app.agents-society-dev-joshua.com',
    appLandlord: 'https://landlord.agents-society-dev-joshua.com',
    proxy: 'https://agents-society-dev-joshua.com/',
  },
  sandbox: {
    appAgency: 'https://app.agents-society-sandbox.com',
    appLandlord: 'https://landlord.agents-society-sandbox.com',
    proxy: 'https://agents-society-sandbox.com/',
  },
  production: {
    appAgency: 'https://app.agents-society.com',
    appLandlord: 'https://landlord.agents-society.com',
    proxy: 'https://agents-society.com/',
  },
  local: {
    appAgency: 'https://app.agents-society.test:8080',
    appLandlord: 'https://landlord.agents-society.test:8088',
    proxy: 'https://agents-society.test/',
  },
};

export const getBaseUrl = (subDomain?: string) => {
  let baseUrl;

  switch (window.location.origin) {
    case baseUrls.dev.appAgency:
    case baseUrls.dev.appLandlord:
      baseUrl = baseUrls.dev.proxy;
      break;

    case baseUrls.test.appAgency:
    case baseUrls.test.appLandlord:
      baseUrl = baseUrls.test.proxy;
      break;

    case baseUrls.preprod.appAgency:
    case baseUrls.preprod.appLandlord:
      baseUrl = baseUrls.preprod.proxy;
      break;

    case baseUrls.demo.appAgency:
    case baseUrls.demo.appLandlord:
      baseUrl = baseUrls.demo.proxy;
      break;

    case baseUrls.presentation.appAgency:
    case baseUrls.presentation.appLandlord:
      baseUrl = baseUrls.presentation.proxy;
      break;

    case baseUrls.stage.appAgency:
    case baseUrls.stage.appLandlord:
      baseUrl = baseUrls.stage.proxy;
      break;

    case baseUrls.gk.appAgency:
    case baseUrls.gk.appLandlord:
      baseUrl = baseUrls.gk.proxy;
      break;

    case baseUrls.usyk.appAgency:
    case baseUrls.usyk.appLandlord:
      baseUrl = baseUrls.usyk.proxy;
      break;

    case baseUrls.vidic.appAgency:
    case baseUrls.vidic.appLandlord:
      baseUrl = baseUrls.vidic.proxy;
      break;

    case baseUrls.dubois.appAgency:
    case baseUrls.dubois.appLandlord:
      baseUrl = baseUrls.dubois.proxy;
      break;

    case baseUrls.rocks.appAgency:
    case baseUrls.rocks.appLandlord:
      baseUrl = baseUrls.rocks.proxy;
      break;

    case baseUrls.fury.appAgency:
    case baseUrls.fury.appLandlord:
      baseUrl = baseUrls.fury.proxy;
      break;

    case baseUrls.sandbox.appAgency:
    case baseUrls.sandbox.appLandlord:
      baseUrl = baseUrls.sandbox.proxy;
      break;

    case baseUrls.khan.appAgency:
    case baseUrls.khan.appLandlord:
      baseUrl = baseUrls.khan.proxy;
      break;

    case baseUrls.thumper.appAgency:
    case baseUrls.thumper.appLandlord:
      baseUrl = baseUrls.thumper.proxy;
      break;

    case baseUrls.joshua.appAgency:
    case baseUrls.joshua.appLandlord:
      baseUrl = baseUrls.joshua.proxy;
      break;

    case baseUrls.creed.appAgency:
    case baseUrls.creed.appLandlord:
      baseUrl = baseUrls.creed.proxy;
      break;

    case baseUrls.production.appAgency:
    case baseUrls.production.appLandlord:
      baseUrl = baseUrls.production.proxy;
      break;

    case baseUrls.local.appAgency:
    case baseUrls.local.appLandlord:
      baseUrl = baseUrls.local.proxy;
      break;

    default:
      baseUrl = baseUrls.test.proxy;
  }

  if (subDomain) {
    return baseUrl.replace('https://', `https://${subDomain}.`);
  }

  return baseUrl;
};
