import { Fade, Snackbar } from '@material-ui/core';
import { AlertProps, Color, Alert as MuiAlert } from '@material-ui/lab';

export interface NotificationProps {
  autoHideDuration?: number | null;
  type?: Color;
  className?: string;
}

interface NotificationState {
  message: React.ReactNode;
  type?: Color;
  notificationProps: Partial<NotificationProps>;
  open: boolean;
}

interface NotificationItem {
  message: React.ReactNode;
  type?: Color;
  notificationProps?: Partial<NotificationProps>;
}

let notificationQueue: NotificationItem[] = [];

const defaultProps = {
  autoHideDuration: 5000,
  className: '',
};

let showNotificationFn: (config: NotificationItem) => void;
let hideNotificationFn: () => void;
let isOpenNotificationFn: () => boolean;

export function showNotification(
  message: React.ReactNode,
  type?: Color,
  notificationProps?: Partial<NotificationProps>
) {
  // If the `<Notification />` component is not mounted yet, then queue the notification
  if (typeof showNotificationFn !== 'function') {
    notificationQueue.push({ message, type, notificationProps });
    return;
  }

  showNotificationFn({ message, type, notificationProps });
}

export function hideNotification() {
  hideNotificationFn();
}

export function isOpenNotification(): boolean {
  return isOpenNotificationFn();
}

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NotificationComponent extends React.Component<NotificationProps, NotificationState> {
  readonly state = {
    message: '',
    type: 'info' as Color,
    notificationProps: defaultProps,
    open: false,
  };

  componentDidMount() {
    showNotificationFn = this.open;
    hideNotificationFn = this.close;
    isOpenNotificationFn = this.isOpenNotification;

    // Process any queued notifications on mount. This overcomes race-conditions that may happen where the app may invoke to show notification(s) before the `<Notification />` component has mounted
    this.processQueuedNotifications();
  }

  render() {
    const { message, type, notificationProps, open } = this.state;
    const { autoHideDuration } = notificationProps;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={autoHideDuration}
        open={open}
        TransitionComponent={Fade}
        onClose={this.close}
      >
        <Alert onClose={this.close} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    );
  }

  private processQueuedNotifications = () => {
    // If there are queued notifications, then process them
    if (notificationQueue.length) {
      notificationQueue.forEach(({ message, type, notificationProps }) => {
        this.open({ message, type, notificationProps });
      });
      notificationQueue = [];
    }
  };

  private isOpenNotification = () => this.state.open;

  private open = ({ message, type = 'info', notificationProps }: NotificationItem) => {
    this.setState({
      message,
      type,
      notificationProps: {
        ...defaultProps,
        ...notificationProps,
      },
      open: true,
    });
  };

  private close = () => {
    this.setState({ notificationProps: defaultProps, open: false });
  };
}

export const Notification = NotificationComponent;
