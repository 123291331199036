import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import styles from './UserCheck1.styles';

export interface Icon1Props extends WithStyles<typeof styles> {
  color?: string;
}

const UserCheck1IconComponent: FC<Icon1Props> = ({ classes, color = '#7171E3' }) => {
  return (
    <SvgIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      classes={{ root: classes.root }}
    >
      <path
        d="M6 7.75H3.75C3.05222 7.75 2.70333 7.75 2.41943 7.83612C1.78023 8.03002 1.28002 8.53023 1.08612 9.16943C1 9.45333 1 9.80222 1 10.5M8 9L9 10L11 8M7.25 3.75C7.25 4.99264 6.24264 6 5 6C3.75736 6 2.75 4.99264 2.75 3.75C2.75 2.50736 3.75736 1.5 5 1.5C6.24264 1.5 7.25 2.50736 7.25 3.75Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const UserCheck1Icon = withStyles(styles)(UserCheck1IconComponent);
