import { createStyles } from '@material-ui/core/styles';

export default function styles() {
  return createStyles({
    root: {
      fontSize: 12,
      fill: 'transparent',
    },
  });
}
