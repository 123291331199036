import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DisposalInsights } from '@agency/apis/disposals';

export interface DisposalManageInsightsState {
  data: DisposalInsights | null;
  error: string | null;
  loading: boolean;
}

const storeName = 'disposalManageInsights';

const initialState: DisposalManageInsightsState = {
  data: null,
  error: null,
  loading: false,
};

const disposalManageInsightsSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setLoading(state: DisposalManageInsightsState, action: PayloadAction<boolean>) {
      state.loading = action.payload;
      state.data = null;
    },
    getDataSuccess(state: DisposalManageInsightsState, action: PayloadAction<DisposalInsights | null>) {
      state.loading = false;
      state.data = action.payload;
    },
    getDataFailure(state: DisposalManageInsightsState, action: PayloadAction<string>) {
      state.data = null;
      state.loading = false;
      state.error = action.payload;
    },
    clearState(state: DisposalManageInsightsState) {
      state.data = null;
      state.loading = false;
      state.error = null;
    },
  },
});

export const disposalManageInsightsActions = {
  setLoading: disposalManageInsightsSlice.actions.setLoading,
  getDataSuccess: disposalManageInsightsSlice.actions.getDataSuccess,
  getDataFailure: disposalManageInsightsSlice.actions.getDataFailure,
  getDataRequest: createAction<Id>(`${storeName}/getDataRequest`),
  clearState: disposalManageInsightsSlice.actions.clearState,
};

export const disposalManageInsightsReducer = disposalManageInsightsSlice.reducer;
