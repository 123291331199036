import { LoadScript } from '@react-google-maps/api';
import cx from 'classnames';
import { FC } from 'react';

import { usePusher } from '@core/contexts/PusherContext';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Loading } from '@shared/components/loading';

import { styles } from './LayoutFullScreen.styles';

export interface LayoutFullScreenProps extends WithStyles<typeof styles> {
  googleMapsApiKey: string;
}

const LayoutFullScreenComponent: FC<LayoutFullScreenProps> = ({ children, classes, googleMapsApiKey }) => {
  const { toasts } = usePusher();

  return (
    <LoadScript id="google-map-script" googleMapsApiKey={googleMapsApiKey} loadingElement={<Loading absolute />}>
      <div className={cx(classes.app)}>{children}</div>
      {!!toasts.length && <div className={classes.toasts}>{toasts.map((item) => item.content)}</div>}
    </LoadScript>
  );
};

export const LayoutFullScreen = withStyles(styles)(LayoutFullScreenComponent);
