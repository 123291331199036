import { createContext, FC, useContext, useMemo } from 'react';

import { CompanyRoles, CompanyStatus } from '@apis/companiesApi.types';
import { ContactRoles, ContactStatus } from '@apis/contactsApi.types';
import { FeeStatus } from '@apis/feesApi.types';
import { RequirementStatus } from '@apis/requirementsApi.types';
import { TaskFrequency } from '@apis/tasksApi.types';
import { UserRoles } from '@apis/usersApi.types';
import { useAgencyConstants, useConfig } from '@core/contexts/ConfigContext';
import { t } from '@core/i18n';
import { ShortWeekDay } from '@modules/insights/pages/viewings-old/components/viewings-drawer-old/components/viewings-task-edit/ViewingsTaskEdit.types';
import { GroupedOption } from '@shared/components/group-select-new';
import { CoreOptionsProvider, useCoreOptions, UseCoreOptionsResult } from '@shared/contexts/OptionsContext';
import { mapStringRecord } from '@shared/utils/common';
import {
  filterOptions,
  groupOptionsByBuildingType,
  idArrayToNumberOptions,
  objectToNumberOptions,
  objectToOptions,
  OptionsItem,
  societiesToSelectOptions,
  sourceOptionsSelectOptions,
  stringObjectToNumberOptions,
  stringObjectToOptions,
} from '@shared/utils/options';

interface UseOptionsResult extends UseCoreOptionsResult {
  accuracyOptions: OptionsItem<string>[];
  acquisitionIntelStatusOptions: OptionsItem<RequirementStatus>[];
  advisoryStatusOptions: OptionsItem<number>[];
  advisoryTypeOptions: OptionsItem<number>[];
  alienationOptions: OptionsItem<number>[];
  archiveRadioOptions: OptionsItem[];
  availabilityMonthOptions: OptionsItem[];
  availableBuildingTypeGroupOptions: GroupedOption<string>[];
  availableLettingTypeOptions: OptionsItem<number>[];
  availableMeasureOptions: OptionsItem[];
  availableSizeMetricOptions: OptionsItem<Metric>[];
  availableTaskTypeOptions: OptionsItem[];
  booleanOptions: OptionsItem<number>[];
  booleanSpecOptions: OptionsItem<string>[];
  booleanStringOptions: OptionsItem<string>[];
  breeamRatingOptions: OptionsItem<string>[];
  buildStatusOptions: OptionsItem[];
  bulkStatusOptions: OptionsItem<number>[];
  classOfUseOptions: OptionsItem<string>[];
  companyRoleOptions: OptionsItem<CompanyRoles>[];
  companyStatusOptions: OptionsItem<CompanyStatus>[];
  companyTagOptions: OptionsItem<number>[];
  companyTypeOptions: OptionsItem<string>[];
  confirmedEventOptions: OptionsItem[];
  consentSourceOptions: OptionsItem[];
  contactRoleOptions: OptionsItem<ContactRoles>[];
  contactStatusOptions: OptionsItem<ContactStatus>[];
  contactTitleOptions: OptionsItem[];
  contactTagOptions: OptionsItem<number>[];
  contactTypeOptions: OptionsItem[];
  countryOptions: OptionsItem<string>[];
  creatableAdvisoryStatusOptions: OptionsItem[];
  creatableTaskTypeOptions: OptionsItem[];
  currencyOptions: OptionsItem<string>[];
  devPipelineFundingOptions: OptionsItem[];
  devPipelineLikelihoodOptions: OptionsItem<string>[];
  devPipelineStatusOptions: OptionsItem[];
  devPipelineTypeOptions: OptionsItem[];
  diaryTaskStatusOptions: OptionsItem[];
  disposalConditionTypeOptions: OptionsItem[];
  disposalFittedSpaceOptions: OptionsItem[];
  disposalGradeOptions: OptionsItem[];
  disposalPublishedToOptions: OptionsItem[];
  disposalStatusOptions: OptionsItem[];
  disposalTermsOfEngagementOptions: OptionsItem[];
  disposalTagOptions: OptionsItem[];
  egRankingsLeaseOptions: OptionsItem<string>[];
  egRankingsSaleOptions: OptionsItem<string>[];
  epcBandOptions: OptionsItem[];
  epcBandRangeOptions: OptionsItem<string>[];
  epcExemptionReasonOptions: OptionsItem<number>[];
  eventNotificationOptions: OptionsItem[];
  feeCurrencyOptions: OptionsItem[];
  feesAdvisoryStages: OptionsItem<number>[];
  feesAllStagesOptions: OptionsItem<number>[];
  feesBasisOptions: OptionsItem[];
  feesDisposalLeaseStagesOptions: OptionsItem<number>[];
  feesPaymentStatusOptions: OptionsItem<number>[];
  feesRequirementLeaseStagesOptions: OptionsItem<number>[];
  feesSourceCrossSellExternalOptions: OptionsItem[];
  feesSourceCrossSellInternalOptions: OptionsItem[];
  feesSourceOptions: OptionsItem<number>[];
  feesSourceOwnActivityOptions: OptionsItem[];
  feesStatusOptions: OptionsItem<FeeStatus>[];
  feesTypeOptions: OptionsItem[];
  filterableBulkStatusOptions: OptionsItem<number>[];
  filterableRentMetricOptions: OptionsItem<Metric>[];
  fitoutConceptOptions: OptionsItem[];
  fitoutConditionOptions: OptionsItem[];
  floorUnitAvailabilityColumnOptions: OptionsItem[];
  floorUnitNameOptions: OptionsItem<string>[];
  includeInstructionOptions: OptionsItem[];
  investmentCurrencyOptions: OptionsItem<string>[];
  investmentCurrencySymbolOptions: OptionsItem<string>[];
  investmentGradeOptions: OptionsItem[];
  investmentMarketStatusOptions: OptionsItem<number>[];
  investmentMetricOptions: OptionsItem[];
  investmentProfileOptions: OptionsItem[];
  investmentStrategyOptions: OptionsItem[];
  investmentStructureOptions: OptionsItem[];
  investorRankingOptions: OptionsItem[];
  investorRegionOptions: OptionsItem[];
  investorTypeOptions: OptionsItem[];
  jointExclusiveInstructionOptions: OptionsItem[];
  kycCompletedOptions: OptionsItem[];
  leaseBreakTypeOptions: OptionsItem[];
  leaseExpiryReasonOptions: OptionsItem[];
  leaseTypeOptions: OptionsItem[];
  legalFeesApplicableOptions: OptionsItem<string>[];
  lettingTransactionCompanyLandlordRoleOptions: OptionsItem<CompanyRoles>[];
  lettingTransactionCompanyTenantRoleOptions: OptionsItem<CompanyRoles>[];
  lettingTransactionContactLandlordRoleOptions: OptionsItem<ContactRoles>[];
  lettingTransactionContactTenantRoleOptions: OptionsItem<ContactRoles>[];
  lettingTransactionUserLandlordRoleOptions: OptionsItem<UserRoles>[];
  lettingTransactionUserTenantRoleOptions: OptionsItem<UserRoles>[];
  lettingTransactionTagOptions: OptionsItem<number>[];
  marketingListOptions: OptionsItem[];
  matchDisposalsSortOptions: OptionsItem[];
  metricOptions: OptionsItem<Metric>[];
  monthQuarterOptions: OptionsItem[];
  nationalityOptions: OptionsItem[];
  nineteenFiftyFourActOptions: OptionsItem<string>[];
  officesOptions: OptionsItem<number>[];
  openEventOptions: OptionsItem[];
  ownershipTypeOptions: OptionsItem<number>[];
  parkingChargeMetricOptions: OptionsItem<Metric>[];
  planningStatusOptions: OptionsItem[];
  preferredSourceOptions: OptionsItem[];
  quarterOptions: OptionsItem<string>[];
  reminderDaysOptions: OptionsItem<number>[];
  reminderMonthsOptions: OptionsItem<number>[];
  rentAdjustmentReasonsOptions: OptionsItem<string>[];
  rentBusinessRateOptions: OptionsItem<string>[];
  rentMetricOptions: OptionsItem<Metric>[];
  rentTypeOptions: OptionsItem[];
  repairsInsuranceOptions: OptionsItem<string>[];
  requirementListFilterOptions: OptionsItem[];
  requirementMatchRadiusOptions: OptionsItem[];
  requirementReasonOptions: OptionsItem<string>[];
  requirementStatusOptions: OptionsItem<RequirementStatus>[];
  requirementTypeOptions: OptionsItem[];
  saleTransactionCompanyRoleOptions: OptionsItem<CompanyRoles>[];
  saleTransactionContactRoleOptions: OptionsItem<ContactRoles>[];
  saleTransactionUserRoleOptions: OptionsItem<UserRoles>[];
  saleTransactionTagOptions: OptionsItem<number>[];
  saleTypeOptions: OptionsItem<string>[];
  scheduleOfConditionOptions: OptionsItem<string>[];
  segmentOptions: OptionsItem[];
  showEventOptions: OptionsItem[];
  societyDisposalReportTypeOptions: OptionsItem[];
  societyOptions: OptionsItem[];
  societyPropertyTypeOptions: OptionsItem<string>[];
  societyRegionOptions: OptionsItem[];
  sourceOptions: OptionsItem[];
  tagOptions: OptionsItem[];
  taskRepeatOptions: OptionsItem<TaskFrequency>[];
  teamOptions: OptionsItem<number>[];
  teamSegmentOptions: OptionsItem[];
  teamStatusOptions: OptionsItem[];
  tenancyStatusOptions: OptionsItem[];
  tenureOptions: OptionsItem[];
  transactionStatusOptions: OptionsItem<number>[];
  vatApplicableOptions: OptionsItem<string>[];
  weekDayOptions: OptionsItem<ShortWeekDay>[];
  zonesOptions: OptionsItem<string>[];
}

export const OptionsContext = createContext<UseOptionsResult>({} as UseOptionsResult);

export const useOptions = () => {
  return useContext(OptionsContext);
};

const OptionsProvider: FC = ({ children }) => {
  const coreOptions = useCoreOptions();

  const { offices, segments, team_segments, teams, user } = useConfig();
  const { constants } = useAgencyConstants();

  // -- All --

  const allAdvisoryStatusOptions = useMemo(
    () => idArrayToNumberOptions(constants.advisoryStatusOptions),
    [constants.advisoryStatusOptions]
  );

  const allSourceOptions = useMemo(
    () => sourceOptionsSelectOptions(constants.localisation.sourceOptions),
    [constants.localisation.sourceOptions]
  );

  const allTaskTypeOptions = useMemo(() => objectToOptions(constants.taskTypes), [constants.taskTypes]);

  // -- Available --

  const availableTaskTypeOptions = useMemo(
    () => filterOptions(allTaskTypeOptions, user.organisation?.settings.task_types_visible, true),
    [allTaskTypeOptions, user.organisation?.settings.task_types_visible]
  );

  // -- Options --

  const result: UseOptionsResult = {
    ...coreOptions,

    accuracyOptions: useMemo(
      () => [
        { id: 'estimated', label: t('estimated') },
        { id: 'confirmed', label: t('confirmed') },
      ],
      []
    ),
    acquisitionIntelStatusOptions: useMemo(
      () => idArrayToNumberOptions(constants.acquisitionIntelStatusOptions),
      [constants.acquisitionIntelStatusOptions]
    ),
    advisoryStatusOptions: allAdvisoryStatusOptions,
    advisoryTypeOptions: useMemo(
      () => idArrayToNumberOptions(constants.advisoryTypeOptions),
      [constants.advisoryTypeOptions]
    ),
    alienationOptions: useMemo(
      () => [
        { id: 1, label: t('assignsublet_the_whole_sublet_part') },
        { id: 2, label: t('assignsublet_the_whole_only') },
        { id: 3, label: t('assign_the_whole_only') },
        { id: 4, label: t('sublet_the_whole_only') },
        { id: 5, label: t('submit_part_only') },
        { id: 6, label: t('unknown') },
        { id: 7, label: t('alienation_prohibited') },
      ],
      []
    ),
    archiveRadioOptions: useMemo(
      () => [
        { id: 1, label: t('archive') },
        { id: 0, label: t('dont_archive') },
      ],
      []
    ),
    availabilityMonthOptions: useMemo(
      () => objectToOptions(constants.availabilityMonths),
      [constants.availabilityMonths]
    ),
    availableBuildingTypeGroupOptions: useMemo(() => groupOptionsByBuildingType(constants, user), [constants, user]),
    availableLettingTypeOptions: useMemo(
      () =>
        constants.availableLettingTypeOptions.map((label, idx) => ({
          id: idx,
          label,
        })),
      [constants.availableLettingTypeOptions]
    ),
    availableMeasureOptions: useMemo(() => objectToOptions(constants.availableMeasures), [constants.availableMeasures]),
    availableSizeMetricOptions: useMemo(
      () => stringObjectToOptions<Metric>(constants.availableSizeMetrics),
      [constants.availableSizeMetrics]
    ),
    availableTaskTypeOptions,
    breeamRatingOptions: useMemo(
      () => stringObjectToOptions(constants.breeamRatingOptions),
      [constants.breeamRatingOptions]
    ),
    booleanOptions: useMemo(
      () => [
        { id: 1, label: t('yes') },
        { id: 0, label: t('no') },
      ],
      []
    ),
    booleanStringOptions: useMemo(
      () => [
        { label: t('yes'), id: '1' },
        { label: t('no'), id: '0' },
      ],
      []
    ),
    booleanSpecOptions: useMemo(
      () => [
        { label: t('yes'), id: t('yes') },
        { label: t('no'), id: t('no') },
      ],
      []
    ),
    buildStatusOptions: useMemo(() => objectToOptions(constants.buildStatusOptions), [constants.buildStatusOptions]),
    bulkStatusOptions: useMemo(() => stringObjectToNumberOptions(constants.bulkStatuses), [constants.bulkStatuses]),
    classOfUseOptions: useMemo(() => stringObjectToOptions(constants.classOfUse), [constants.classOfUse]),
    companyRoleOptions: useMemo(
      () => stringObjectToNumberOptions<CompanyRoles>(constants.companyRoles),
      [constants.companyRoles]
    ),
    companyStatusOptions: useMemo(
      () => idArrayToNumberOptions<CompanyStatus>(constants.companyStatusOptions),
      [constants.companyStatusOptions]
    ),
    companyTagOptions: useMemo(() => stringObjectToNumberOptions(constants.companyTags), [constants.companyTags]),
    companyTypeOptions: useMemo(() => stringObjectToOptions(constants.companyTypes), [constants.companyTypes]),
    confirmedEventOptions: useMemo(
      () => [
        { id: '0', label: t('provisional_1') },
        { id: '1', label: t('confirmed') },
      ],
      []
    ),
    consentSourceOptions: useMemo(
      () => [
        { id: 1, label: t('website') },
        { id: 2, label: t('verbal') },
        { id: 3, label: t('email') },
      ],
      []
    ),
    contactRoleOptions: useMemo(
      () => stringObjectToNumberOptions<ContactRoles>(constants.contactRoles),
      [constants.contactRoles]
    ),
    contactStatusOptions: useMemo(
      () => idArrayToNumberOptions<ContactStatus>(constants.contactStatusOptions),
      [constants.contactStatusOptions]
    ),
    contactTitleOptions: useMemo(
      () => [
        { id: 1, label: t('mr') },
        { id: 2, label: t('mrs') },
        { id: 3, label: t('ms') },
        { id: 4, label: t('miss') },
        { id: 5, label: t('dr') },
        { id: 6, label: t('professor') },
        { id: 7, label: t('reverend') },
        { id: 8, label: t('pastor') },
        { id: 9, label: t('rabbi') },
        { id: 10, label: t('sister') },
      ],
      []
    ),
    contactTagOptions: useMemo(() => stringObjectToNumberOptions(constants.contactTags), [constants.contactTags]),

    contactTypeOptions: useMemo(() => objectToOptions(constants.contactTypes), [constants.contactTypes]),
    countryOptions: useMemo(() => objectToOptions(constants.countryOptions, true), [constants.countryOptions]),
    creatableAdvisoryStatusOptions: useMemo(
      () => filterOptions(allAdvisoryStatusOptions, constants.creatableAdvisoryStatusOptions),
      [allAdvisoryStatusOptions, constants.creatableAdvisoryStatusOptions]
    ),
    creatableTaskTypeOptions: useMemo(
      () => filterOptions(availableTaskTypeOptions, constants.creatableTaskTypes, true),
      [availableTaskTypeOptions, constants.creatableTaskTypes]
    ),
    currencyOptions: useMemo(
      () =>
        constants.localisation.currencyOptionsDropdown.map((option) => ({
          id: option.id,
          label: option.name,
        })),
      [constants.localisation.currencyOptionsDropdown]
    ),
    devPipelineFundingOptions: useMemo(
      () => objectToOptions(constants.devPipelineFundingOptions),
      [constants.devPipelineFundingOptions]
    ),
    devPipelineLikelihoodOptions: useMemo(
      () => stringObjectToOptions(constants.devPipelineLikelihoodOptions),
      [constants.devPipelineLikelihoodOptions]
    ),
    devPipelineStatusOptions: useMemo(
      () => objectToOptions(constants.devPipelineStatusOptions),
      [constants.devPipelineStatusOptions]
    ),
    devPipelineTypeOptions: useMemo(
      () => objectToOptions(constants.devPipelineTypeOptions),
      [constants.devPipelineTypeOptions]
    ),
    diaryTaskStatusOptions: useMemo(
      () => [
        { label: t('all_statuses'), id: '' },
        { label: t('open'), id: 'open' },
        { label: t('completed'), id: 'completed' },
      ],
      []
    ),
    disposalConditionTypeOptions: useMemo(
      () => objectToOptions(constants.disposalConditionTypes),
      [constants.disposalConditionTypes]
    ),
    disposalFittedSpaceOptions: useMemo(
      () => objectToOptions(constants.disposalFittedSpaceOptions),
      [constants.disposalFittedSpaceOptions]
    ),
    disposalGradeOptions: useMemo(
      () => objectToOptions(constants.disposalGradeOptions),
      [constants.disposalGradeOptions]
    ),
    disposalPublishedToOptions: useMemo(
      () => objectToOptions(constants.disposalsPublishedToOptions),
      [constants.disposalsPublishedToOptions]
    ),
    disposalStatusOptions: useMemo(
      () => objectToOptions(constants.disposalStatusOptions),
      [constants.disposalStatusOptions]
    ),
    disposalTermsOfEngagementOptions: useMemo(
      () => objectToOptions(constants.disposalTermsOfEngagementOptions),
      [constants.disposalTermsOfEngagementOptions]
    ),
    disposalTagOptions: useMemo(() => objectToOptions(constants.disposalTags), [constants.disposalTags]),
    egRankingsLeaseOptions: useMemo(
      () => stringObjectToOptions(constants.egRankingsLeaseOptions),
      [constants.egRankingsLeaseOptions]
    ),
    egRankingsSaleOptions: useMemo(
      () => stringObjectToOptions(constants.egRankingsSaleOptions),
      [constants.egRankingsSaleOptions]
    ),
    epcBandOptions: useMemo(() => objectToOptions(constants.epcBandOptions), [constants.epcBandOptions]),
    epcBandRangeOptions: useMemo(
      () => stringObjectToOptions(constants.epcBandRangeOptions),
      [constants.epcBandRangeOptions]
    ),
    epcExemptionReasonOptions: useMemo(
      () => [
        { id: 1, label: t('currently_being_constructed_or_undergoing_major_refurbishment') },
        { id: 2, label: t('due_for_demolition') },
        { id: 3, label: t('temporary_building_bracket_less_than_2_years_bracket') },
        {
          id: 4,
          label: t('industrial_sites_workshops_and_nonresidential_agricultural_buildings_with_low_energy_demand'),
        },
        {
          id: 5,
          label: t(
            'residential_agricultural_building_in_use_by_a_sector_covered_by_national_sectoral_agreement_on_energy_performance'
          ),
        },
        { id: 6, label: t('standalone_building_50m2') },
        { id: 7, label: t('listed_building') },
        { id: 8, label: t('place_of_worship') },
        { id: 9, label: t('no_building_present') },
        { id: 10, label: t('serviced_office_subject_to_legal_review') },
        { id: 11, label: t('epc_has_been_commissioned_will_be_available_in_28_days') },
      ],
      []
    ),
    eventNotificationOptions: useMemo(
      // TODO: Refactor - remove this, use booleanStringOptions
      () => [
        { id: '0', label: 'No' },
        { id: '1', label: 'Yes' },
      ],
      []
    ),
    feeCurrencyOptions: useMemo(
      () =>
        mapStringRecord(constants.feeCurrencyOptions, (option) => ({
          id: option?.iso,
          label: option?.name,
        })),
      [constants.feeCurrencyOptions]
    ),
    feesAdvisoryStages: useMemo(
      () => stringObjectToNumberOptions(constants.feesAdvisoryStages),
      [constants.feesAdvisoryStages]
    ),
    feesAllStagesOptions: useMemo(
      () => stringObjectToNumberOptions(constants.feesAllStagesOptions),
      [constants.feesAllStagesOptions]
    ),
    feesBasisOptions: useMemo(() => objectToOptions(constants.feesBasisOptions), [constants.feesBasisOptions]),
    feesDisposalLeaseStagesOptions: useMemo(
      () => stringObjectToNumberOptions(constants.feesDisposalLeaseStages),
      [constants.feesDisposalLeaseStages]
    ),
    feesPaymentStatusOptions: useMemo(
      () => idArrayToNumberOptions(constants.feesPaymentStatusOptions),
      [constants.feesPaymentStatusOptions]
    ),
    feesRequirementLeaseStagesOptions: useMemo(
      () => stringObjectToNumberOptions(constants.feesRequirementLeaseStages),
      [constants.feesRequirementLeaseStages]
    ),
    feesSourceCrossSellExternalOptions: useMemo(
      () => stringObjectToOptions(constants.feesSourceCrossSellExternalOptions),
      [constants.feesSourceCrossSellExternalOptions]
    ),
    feesSourceCrossSellInternalOptions: useMemo(
      () => stringObjectToOptions(constants.feesSourceCrossSellInternalOptions),
      [constants.feesSourceCrossSellInternalOptions]
    ),
    feesSourceOptions: useMemo(
      () => idArrayToNumberOptions(constants.feesSourceOptions),
      [constants.feesSourceOptions]
    ),
    feesSourceOwnActivityOptions: useMemo(
      () => stringObjectToOptions(constants.feesSourceOwnActivityOptions),
      [constants.feesSourceOwnActivityOptions]
    ),
    feesStatusOptions: useMemo(
      () => idArrayToNumberOptions(constants.feesStatusOptions),
      [constants.feesStatusOptions]
    ),
    feesTypeOptions: useMemo(() => objectToOptions(constants.feesTypeOptions), [constants.feesTypeOptions]),
    filterableBulkStatusOptions: useMemo(
      () => stringObjectToNumberOptions(constants.bulkStatusesFilterable),
      [constants.bulkStatusesFilterable]
    ),
    filterableRentMetricOptions: useMemo(
      () => stringObjectToOptions<Metric>(constants.rentMetricsFilterable),
      [constants.rentMetricsFilterable]
    ),
    fitoutConceptOptions: useMemo(
      () => objectToOptions(constants.fitoutConceptOptions),
      [constants.fitoutConceptOptions]
    ),
    fitoutConditionOptions: useMemo(
      () => objectToOptions(constants.fitoutConditionOptions),
      [constants.fitoutConditionOptions]
    ),
    floorUnitAvailabilityColumnOptions: useMemo(
      () => objectToOptions(constants.floorUnitAvailabilityColumns),
      [constants.floorUnitAvailabilityColumns]
    ),
    floorUnitNameOptions: useMemo(
      () => objectToOptions(constants.floorUnitNameOptions),
      [constants.floorUnitNameOptions]
    ),
    includeInstructionOptions: useMemo(
      () => [
        { id: '1', label: t('include_our_instructions') },
        { id: '0', label: t('include_market_intel') },
      ],
      []
    ),
    investmentCurrencyOptions: useMemo(
      () =>
        mapStringRecord(constants.investmentCurrencyOptions, (option) => ({
          id: option?.iso,
          label: option?.name,
        })),
      [constants.investmentCurrencyOptions]
    ),
    investmentCurrencySymbolOptions: useMemo(
      () =>
        mapStringRecord(constants.investmentCurrencyOptions, (option) => ({
          id: option?.iso,
          label: option?.symbol,
        })),
      [constants.investmentCurrencyOptions]
    ),
    investmentGradeOptions: useMemo(
      () => objectToOptions(constants.investmentGradeOptions),
      [constants.investmentGradeOptions]
    ),
    investmentMarketStatusOptions: useMemo(
      () => stringObjectToNumberOptions(constants.investmentMarketStatusOptions),
      [constants.investmentMarketStatusOptions]
    ),
    investmentMetricOptions: useMemo(
      () => stringObjectToOptions(constants.investmentMetrics),
      [constants.investmentMetrics]
    ),
    investmentProfileOptions: useMemo(
      () => objectToOptions(constants.investmentProfileOptions),
      [constants.investmentProfileOptions]
    ),
    investmentStrategyOptions: useMemo(
      () => objectToOptions(constants.investmentStrategyOptions),
      [constants.investmentStrategyOptions]
    ),
    investmentStructureOptions: useMemo(
      () => objectToOptions(constants.investmentStructureOptions),
      [constants.investmentStructureOptions]
    ),
    investorRankingOptions: useMemo(
      () => objectToOptions(constants.investorRankingOptions),
      [constants.investorRankingOptions]
    ),
    investorRegionOptions: useMemo(
      () => objectToOptions(constants.investorRegionOptions),
      [constants.investorRegionOptions]
    ),
    investorTypeOptions: useMemo(() => objectToOptions(constants.investorTypeOptions), [constants.investorTypeOptions]),
    jointExclusiveInstructionOptions: useMemo(
      () => [
        { id: 'exclusive_instructions', label: t('exclusive_instructions') },
        { id: 'joint_instructions', label: t('joint_instructions') },
      ],
      []
    ),
    kycCompletedOptions: useMemo(() => objectToOptions(constants.kycCompletedOptions), [constants.kycCompletedOptions]),
    leaseBreakTypeOptions: useMemo(
      () => [
        { id: 'landlord_tenant', label: t('landlord_and_tenant') },
        { id: 'landlord', label: t('landlord_only') },
        { id: 'tenant', label: t('tenant_only') },
      ],
      []
    ),
    leaseExpiryReasonOptions: useMemo(
      () => objectToOptions(constants.leaseExpiryReasonOptions),
      [constants.leaseExpiryReasonOptions]
    ),
    leaseTypeOptions: useMemo(() => objectToOptions(constants.leaseTypes), [constants.leaseTypes]),
    legalFeesApplicableOptions: useMemo(
      () => objectToOptions(constants.legalFeesApplicableOptions),
      [constants.legalFeesApplicableOptions]
    ),
    lettingTransactionCompanyLandlordRoleOptions: useMemo(
      () => stringObjectToNumberOptions<CompanyRoles>(constants.lettingTransactionCompanyLandlordRoles),
      [constants.lettingTransactionCompanyLandlordRoles]
    ),
    lettingTransactionCompanyTenantRoleOptions: useMemo(
      () => stringObjectToNumberOptions<CompanyRoles>(constants.lettingTransactionCompanyTenantRoles),
      [constants.lettingTransactionCompanyTenantRoles]
    ),
    lettingTransactionContactLandlordRoleOptions: useMemo(
      () => stringObjectToNumberOptions<ContactRoles>(constants.lettingTransactionContactLandlordRoles),
      [constants.lettingTransactionContactLandlordRoles]
    ),
    lettingTransactionContactTenantRoleOptions: useMemo(
      () => stringObjectToNumberOptions<ContactRoles>(constants.lettingTransactionContactTenantRoles),
      [constants.lettingTransactionContactTenantRoles]
    ),
    lettingTransactionUserLandlordRoleOptions: useMemo(
      () => stringObjectToNumberOptions<UserRoles>(constants.lettingTransactionUserLandlordRoles),
      [constants.lettingTransactionUserLandlordRoles]
    ),
    lettingTransactionUserTenantRoleOptions: useMemo(
      () => stringObjectToNumberOptions<UserRoles>(constants.lettingTransactionUserTenantRoles),
      [constants.lettingTransactionUserTenantRoles]
    ),

    lettingTransactionTagOptions: useMemo(
      () => stringObjectToNumberOptions(constants.lettingTransactionTags),
      [constants.lettingTransactionTags]
    ),

    saleTransactionTagOptions: useMemo(
      () => stringObjectToNumberOptions(constants.saleTransactionTags),
      [constants.saleTransactionTags]
    ),

    marketingListOptions: useMemo(
      () => constants.marketingLists.map((option) => ({ id: option.id, label: option.name })),
      [constants.marketingLists]
    ),
    matchDisposalsSortOptions: useMemo(
      () => [
        { id: 'published_at-desc', label: t('published_at') },
        { id: 'updated_at-desc', label: t('updated_at') },
        { id: 'size_to-desc', label: t('size_descending') },
        { id: 'size_from-asc', label: t('size_ascending') },
      ],
      []
    ),
    metricOptions: useMemo(() => stringObjectToOptions<Metric>(constants.genericMetrics), [constants.genericMetrics]),
    monthQuarterOptions: useMemo(
      () => [
        { label: t('january'), id: 1 },
        { label: t('february'), id: 2 },
        { label: t('march'), id: 3 },
        { label: t('april'), id: 4 },
        { label: t('may'), id: 5 },
        { label: t('june'), id: 6 },
        { label: t('july'), id: 7 },
        { label: t('august'), id: 8 },
        { label: t('september'), id: 9 },
        { label: t('october'), id: 10 },
        { label: t('november'), id: 11 },
        { label: t('december'), id: 12 },
        { label: t('q1'), id: 13 },
        { label: t('q2'), id: 14 },
        { label: t('q3'), id: 15 },
        { label: t('q4'), id: 16 },
      ],
      []
    ),
    nationalityOptions: useMemo(() => objectToOptions(constants.nationalityOptions), [constants.nationalityOptions]),
    nineteenFiftyFourActOptions: useMemo(
      () => [
        { id: 'yes', label: t('yes') },
        { id: 'no', label: t('no') },
        { id: 'unknown', label: t('unknown') },
      ],
      []
    ),
    officesOptions: useMemo(() => idArrayToNumberOptions(offices), [offices]),
    openEventOptions: useMemo(
      () => [
        { id: '1', label: 'Yes' },
        { id: '0', label: 'No, Invite only' },
      ],
      []
    ),
    ownershipTypeOptions: useMemo(
      () => stringObjectToNumberOptions(constants.availableSaleTypeOptions),
      [constants.availableSaleTypeOptions]
    ),
    parkingChargeMetricOptions: useMemo(
      () => stringObjectToOptions<Metric>(constants.parkingChargeMetrics),
      [constants.parkingChargeMetrics]
    ),
    planningStatusOptions: useMemo(
      () => objectToOptions(constants.planningStatusOptions),
      [constants.planningStatusOptions]
    ),
    preferredSourceOptions: useMemo(
      () => allSourceOptions.filter((option) => constants.preferredSources.includes(option.id)),
      [allSourceOptions, constants.preferredSources]
    ),
    quarterOptions: useMemo(
      () => [
        { id: 'Q1', label: 'Q1' },
        { id: 'Q2', label: 'Q2' },
        { id: 'Q3', label: 'Q3' },
        { id: 'Q4', label: 'Q4' },
      ],
      []
    ),
    requirementReasonOptions: useMemo(() => stringObjectToOptions(constants.requirementReasonOptions), []),
    reminderDaysOptions: useMemo(
      () => [
        { id: -1, label: t('none') },
        { id: 30, label: t('xmonthsx_month_1', { months: 1 }) },
        { id: 60, label: t('xmonthsx_months_1', { months: 2 }) },
        { id: 90, label: t('xmonthsx_months_1', { months: 3 }) },
        { id: 180, label: t('xmonthsx_months_1', { months: 6 }) },
        { id: 270, label: t('xmonthsx_months_1', { months: 9 }) },
        { id: 365, label: t('xmonthsx_months_1', { months: 12 }) },
        { id: 450, label: t('xmonthsx_months_1', { months: 15 }) },
        { id: 540, label: t('xmonthsx_months_1', { months: 18 }) },
        { id: 730, label: t('xmonthsx_months_1', { months: 24 }) },
        { id: 1095, label: t('xmonthsx_months_1', { months: 36 }) },
      ],
      []
    ),
    reminderMonthsOptions: useMemo(
      () => [
        { id: -1, label: t('none') },
        { id: 1, label: t('xmonthsx_month_1', { months: 1 }) },
        { id: 2, label: t('xmonthsx_months_1', { months: 2 }) },
        { id: 3, label: t('xmonthsx_months_1', { months: 3 }) },
        { id: 4, label: t('xmonthsx_months_1', { months: 4 }) },
        { id: 5, label: t('xmonthsx_months_1', { months: 5 }) },
        { id: 6, label: t('xmonthsx_months_1', { months: 6 }) },
        { id: 9, label: t('xmonthsx_months_1', { months: 9 }) },
        { id: 12, label: t('xmonthsx_months_1', { months: 12 }) },
        { id: 15, label: t('xmonthsx_months_1', { months: 15 }) },
        { id: 18, label: t('xmonthsx_months_1', { months: 18 }) },
        { id: 24, label: t('xmonthsx_months_1', { months: 24 }) },
        { id: 36, label: t('xmonthsx_months_1', { months: 36 }) },
      ],
      []
    ),
    // TODO: Get rent adjustment reasons added to backend, maybe?
    rentAdjustmentReasonsOptions: useMemo(
      () =>
        objectToOptions({
          'frontage-to-depth': t('frontage_to_depth'),
          'stepped-access': t('stepped_access'),
          'assumed-term': t('assumed_term'),
          quantum: t('quantum'),
          'floor-to-ceiling-height': t('floor_to_ceiling_height'),
          'lack-of-ancillary-storage': t('lack_of_ancillary_storage'),
          shape: t('shape'),
          masking: t('masking'),
          columns: t('columns'),
          'unit-visibility': t('unit_visibility'),
        }),
      []
    ),
    rentBusinessRateOptions: useMemo(
      () => [
        {
          id: 'exclusive',
          label: t('exclusive'),
          tooltip: t('rent_exclusive_of_business_rates_and_charges'),
        },
        {
          id: 'inclusive',
          label: t('inclusive'),
          tooltip: t('rent_inclusive_of_business_rates_and_charges'),
        },
        {
          id: 'na',
          label: t('na'),
          tooltip: t('not_applicable'),
        },
      ],
      []
    ),
    rentMetricOptions: useMemo(() => stringObjectToOptions<Metric>(constants.rentMetrics), [constants.rentMetrics]),
    rentTypeOptions: useMemo(() => objectToOptions(constants.rentTypes), [constants.rentTypes]),
    repairsInsuranceOptions: useMemo(
      () => [
        { id: 'fri', label: t('fri_bracket_full_repairing_and_insuring_lease_bracket') },
        { id: 'iri', label: t('iri_bracket_internal_repairing_and_insuring_lease_bracket') },
        { id: 'fs', label: t('fully_serviced') },
        { id: 'fr', label: t('full_repairing_lease') },
        { id: 'ir', label: t('internal_repairing_lease') },
        { id: 'vfri', label: t('effective_full_repairing_and_insuring_lease') },
        { id: 'unknown', label: t('unknown') },
      ],
      []
    ),
    requirementListFilterOptions: useMemo(() => {
      return (constants.requirementListOptions || [])
        .filter((option) => option.can_filter)
        .map((option) => ({
          id: option.id,
          label: option.name,
        }));
    }, [constants.requirementListOptions]),
    requirementMatchRadiusOptions: useMemo(
      () =>
        [0, 0.25, 0.5, 1, 2, 5, 10, 25].map((radius) => ({
          id: radius.toString(),
          label: t('search_radius_xradiusx_miles', { radius }),
        })),
      []
    ),
    requirementStatusOptions: useMemo(
      () => idArrayToNumberOptions(constants.requirementStatusOptions),
      [constants.requirementStatusOptions]
    ),
    requirementTypeOptions: useMemo(
      () => [
        { id: 'direct_enquiries', label: t('direct_enquiries') },
        { id: 'agent_led', label: t('agentled') },
        { id: 'our_acquisitions', label: t('our_acquisitions') },
        { id: 'availability_schedules', label: t('availability_schedules') },
      ],
      []
    ),
    saleTransactionCompanyRoleOptions: useMemo(
      () => stringObjectToNumberOptions<CompanyRoles>(constants.saleTransactionCompanyRoles),
      [constants.saleTransactionCompanyRoles]
    ),
    saleTransactionContactRoleOptions: useMemo(
      () => stringObjectToNumberOptions<ContactRoles>(constants.saleTransactionContactRoles),
      [constants.saleTransactionContactRoles]
    ),
    saleTransactionUserRoleOptions: useMemo(
      () => stringObjectToNumberOptions<UserRoles>(constants.saleTransactionUserRoles),
      [constants.saleTransactionUserRoles]
    ),
    saleTypeOptions: useMemo(
      () => [
        { label: t('investment'), id: '1' },
        { label: t('occupational'), id: '0' },
      ],
      []
    ),
    scheduleOfConditionOptions: useMemo(
      () => [
        { id: 'yes', label: t('yes') },
        { id: 'no', label: t('no') },
        { id: 'unknown', label: t('unknown') },
      ],
      []
    ),
    segmentOptions: useMemo(
      () =>
        mapStringRecord(segments, (option) => ({
          id: `${option.id}`,
          label: option.name,
        })),
      [segments]
    ),
    showEventOptions: useMemo(
      () => [
        { label: t('my_events'), id: 'mine' },
        { label: t('team_events'), id: 'teams' },
        { label: t('all_events'), id: '' },
      ],
      []
    ),
    societyDisposalReportTypeOptions: useMemo(
      () => [
        { id: 1, label: 'Competition Schedule' },
        { id: 2, label: 'Feasibility Study' },
        { id: 3, label: 'Market Research' },
      ],
      []
    ),
    societyOptions: useMemo(() => societiesToSelectOptions(user.societies), [user.societies]),
    societyPropertyTypeOptions: useMemo(
      () => stringObjectToOptions(constants.societyPropertyTypes),
      [constants.societyPropertyTypes]
    ),
    societyRegionOptions: useMemo(() => objectToNumberOptions(constants.societyRegions), [constants.societyRegions]),
    sourceOptions: allSourceOptions,
    tagOptions: useMemo(() => constants.tags.map((item) => ({ id: item.id, label: item.name })), [constants.tags]),

    taskRepeatOptions: useMemo(
      () => [
        { label: t('doesnt_repeat'), id: TaskFrequency.None },
        { label: t('daily'), id: TaskFrequency.Daily },
        { label: t('weekly'), id: TaskFrequency.Weekly },
        { label: t('monthly'), id: TaskFrequency.Monthly },
        { label: t('annually'), id: TaskFrequency.Yearly },
      ],
      []
    ),
    teamOptions: useMemo(() => idArrayToNumberOptions(teams), [teams]),
    teamSegmentOptions: useMemo(
      () =>
        mapStringRecord(team_segments, (option) => ({
          id: `${option.id}`,
          label: option.name,
        })),
      [team_segments]
    ),
    teamStatusOptions: useMemo(
      () => [
        { id: 0, label: t('active') },
        { id: 1, label: t('scraper') },
        { id: 2, label: t('scraper_with_meta') },
        { id: 3, label: t('inactive') },
        { id: 10, label: t('suspended') },
        { id: 100, label: t('deleted') },
      ],
      []
    ),
    tenancyStatusOptions: useMemo(
      () => objectToOptions(constants.targetTenancyDetails),
      [constants.targetTenancyDetails]
    ),
    tenureOptions: useMemo(
      () => [
        { id: 'to_let', label: t('to_let') },
        { id: 'for_sale', label: t('occupational_sale') },
        { id: 'investment_sale', label: t('investment_sale') },
      ],
      []
    ),
    transactionStatusOptions: useMemo(
      () => stringObjectToNumberOptions(constants.transactionStatusOptions),
      [constants.transactionStatusOptions]
    ),
    vatApplicableOptions: useMemo(
      () => stringObjectToOptions(constants.vatApplicableOptions),
      [constants.vatApplicableOptions]
    ),
    weekDayOptions: useMemo(
      () => [
        { label: t('monday'), id: ShortWeekDay.Monday },
        { label: t('tuesday'), id: ShortWeekDay.Tuesday },
        { label: t('wednesday'), id: ShortWeekDay.Wednesday },
        { label: t('thursday'), id: ShortWeekDay.Thursday },
        { label: t('friday'), id: ShortWeekDay.Friday },
        { label: t('saturday'), id: ShortWeekDay.Saturday },
        { label: t('sunday'), id: ShortWeekDay.Sunday },
      ],
      []
    ),
    zonesOptions: useMemo(
      () => [
        // TODO: Get zone options added to the backend, maybe?
        { label: t('entire_floor'), id: 'floor' },
        { label: t('zone_a'), id: 'zone_a' },
        { label: t('zone_b'), id: 'zone_b' },
        { label: t('zone_c'), id: 'zone_c' },
        { label: t('zone_d'), id: 'zone_d' },
        { label: t('remainder'), id: 'remainder' },
      ],
      []
    ),
  };

  return <OptionsContext.Provider value={result}>{children}</OptionsContext.Provider>;
};

export const CombinedOptionsProvider: FC = ({ children }) => (
  <CoreOptionsProvider>
    <OptionsProvider>{children}</OptionsProvider>
  </CoreOptionsProvider>
);
