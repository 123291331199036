import { UrlState as UrlStateAgency } from '@agency/modules/disposals/pages/disposal-manage/pages/disposal-reporting/DisposalReporting.context';
import { UrlState as UrlStateLandlord } from '@landlord/modules/disposals/pages/disposal-manage/pages/disposal-reporting/DisposalReporting.context';
import { AppNav, AppNavItemKeys } from '@shared/models/config';

export const getDefaultPath = (appUrl: string, appNav: AppNav) => {
  return getTransformedRoute(appUrl, appNav[appNav.default_page as AppNavItemKeys].url);
};

export const getTransformedRoute = (appUrl: string, url: string): string => {
  if (url.startsWith(appUrl)) {
    return url.slice(appUrl.length);
  }

  return url;
};

export const stringifyQueryParams = (queryParams: UrlStateAgency | UrlStateLandlord): string => {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(queryParams)) {
    if (value || typeof value === 'boolean') {
      params.append(key, value.toString());
    }
  }

  return params.toString();
};

/**
 * Converts a stringified query parameter string into an object.
 *
 * @param queryParams - The stringified query parameters, e.g., "key1=value1&key2=value2".
 * @returns An object where each key-value pair represents a query parameter.
 *
 * @example
 * ```typescript
 * const queryParams = "name=John&age=30";
 * const result = convertStringifiedQueryParamsToObject(queryParams);
 * console.log(result); // { name: "John", age: "30" }
 * ```
 */
export const convertStringifiedQueryParamsToObject = (queryParams: string): Record<string, string> => {
  const params = new URLSearchParams(queryParams);
  const result: Record<string, string> = {};

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
};
