import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},

    app: {
      backgroundColor: colors.grey.body,
      minHeight: '100vh',
      transition: 'all 0.3s ease',
      zIndex: 1,
    },

    toasts: {
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(2),
      justifyContent: 'flex-end',
      left: 0,
      margin: spacing(2),
      overflowY: 'auto',
      position: 'fixed',
      width: 400,
      zIndex: 10000,
    },
  });
